import "./styles.css";
import { v4 } from "uuid";
import Info from "./info";
import "../../utils/extenso";
import { IPainel } from "../login";
import { api } from "../../services/api";
import { LuLogOut } from "react-icons/lu";
import { useState, useEffect, useRef } from "react";
// import {  } from 'react-router-dom'
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import playAudio from "../../utils/play-audio";
import { Box, Modal, Typography } from "@mui/material";
import { IMessageEvent, w3cwebsocket as W3CWebSocket } from "websocket";
import Wheather from "../../components/wheather";
import YouTube from "react-youtube";

export interface ICall {
  name: string;
  local: string;
  password: string;
}

function checkSpecialCall(password: string): {
  output: string[];
  outPassword: any;
} {
  let output: string[] = ["Toque"];
  let outPassword: any = password;
  if (password[0] === "C" || password[0] === "P") {
    output.push({ C: "complementares", P: "Preferencial" }[password[0]]);
    outPassword = password.substring(1, outPassword.length);
  }
  return { output, outPassword };
}

function getPassword(password: string): { output: string[] } {
  let { output, outPassword } = checkSpecialCall(password);
  output = [...output, ...outPassword.extenso().split(" ")];
  return { output };
}

let index = 1;

export default function Home() {
  const [CC, setCC] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [call, setCall] = useState<any>(null);
  const [geoLocation, setGeoLocation] = useState({
    latitude: null,
    longitude: null,
  });
  const [calls, setCalls] = useState<any[]>([]);
  const [haveBacked, setHaveBacked] = useState<boolean>();
  const [wsClient, setWsClient] = useState<W3CWebSocket>(null);
  const [isExecuting, setIsExecuting] = useState<boolean>(false);
  const [panelInfo, setPanelInfo] = useState<IPainel | null>(null);
  const [isPermittedAudio, setIsPermittedAudio] = useState<boolean>(false);

  const navigate = useNavigate();
  const location: any = useLocation();

  useEffect(() => {
    if (!location.state?.haveBacked) {
      navigate("/", { replace: true, state: null });
    }
    window.history.pushState(null, null, document.URL);
    window.addEventListener("popstate", function (event) {
      event.preventDefault();
      setHaveBacked(true);
    });
  }, []);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const permittedAudio = `${localStorage.getItem("@App:permittedAudio")}`;
    permittedAudio != "null"
      ? setIsPermittedAudio(true)
      : setIsPermittedAudio(false);

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          // User has permitted geolocation, position data is available
          var latitude = position.coords.latitude;
          var longitude = position.coords.longitude;
          setGeoLocation({
            latitude,
            longitude,
          });
        },
        function (error) {
          // User denied geolocation or an error occurred
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setGeoLocation({
                latitude: null,
                longitude: null,
              });
              break;
            case error.POSITION_UNAVAILABLE:
              setGeoLocation({
                latitude: null,
                longitude: null,
              });
              break;
            case error.TIMEOUT:
              setGeoLocation({
                latitude: null,
                longitude: null,
              });
              break;
          }
        }
      );
    } else {
      setGeoLocation({
        latitude: null,
        longitude: null,
      });
    }
  }, []);

  const refreshToken = async () => {
    const req = await api();
    const response = await req.post(
      "/auth/login/refresh",
      {},
      {
        headers: {
          Authorization: localStorage.getItem("@App:Token_Painel"),
        },
      }
    );
    if (response.status === 200 && response.data.token) {
      localStorage.setItem("@App:Token_Painel", response.data.token);
    }
  };

  function connect(onmessage2: {
    ({ data }: { data: string }): Promise<void>;
    (message: IMessageEvent): void;
  }) {
    const ENDPOINT = "wss://apipainel.prosesmt.com.br/ws";
    const authorization = `${localStorage.getItem("@App:Token_Painel")}`;
    const client = new W3CWebSocket(
      `${ENDPOINT}?authorization=${authorization}`
    );
    setWsClient(client);

    client.onclose = async () => {
      setTimeout(() => connect(onmessage2), 500);
    };

    client.onerror = async () => {
      await refreshToken();
      setTimeout(() => connect(onmessage2), 500);
    };

    client.onmessage = onmessage2;
  }

  const onmessage = async ({ data }: { data: string }) => {
    try {
      const jsonData = JSON.parse(data);
      if (
        typeof jsonData.SENHA === "string" &&
        jsonData.SENHA.replace(/[0-9]/g, "").length <= 1
      ) {
        let { output } = getPassword(jsonData.SENHA);
        const call = {
          local: jsonData.LOCAL,
          password: jsonData.SENHA,
          name: jsonData.FUNNOME,
        };
        setCC((oldCC) => [...oldCC, { id: index, output, call }]);
        index += 1;
      }
    } catch (err) {
      try {
        const req = await api();
        const response = await req.post(
          "/auth/login/refresh",
          {},
          {
            headers: {
              Authorization: localStorage.getItem("@App:Token_Painel"),
            },
          }
        );
        if (response.status === 200 && response.data.token) {
          localStorage.setItem("@App:Token_Painel", response.data.token);
        }
      } catch (err) {}
    }
  };

  useEffect(() => {
    const panel = JSON.parse(localStorage.getItem("@App:Panel")) as IPainel;
    setPanelInfo(panel);
    const minhaFuncao = () => {
      if (wsClient) {
        if (wsClient?.readyState === wsClient?.OPEN) {
          return;
        } else {
          connect(onmessage);
        }
      }
    };
    connect(onmessage);
    setInterval(minhaFuncao, 1000);
  }, []);

  useEffect(() => {
    if (CC.length > 0) {
      if (isExecuting) {
        return;
      }
      setCall(CC[0]);
      playAudio(CC[0], setIsExecuting, setCalls);
      setCC(() => {
        setCall(null);
        return [];
      });
    }
  }, [CC, isExecuting, call]);

  useEffect(() => {
    let wakeLock = null;

    const requestWakeLock = async () => {
      if ("wakeLock" in navigator) {
        try {
          if ((navigator as any)?.wakeLock) {
            wakeLock = await (navigator as any)?.wakeLock?.request("screen");
            document.addEventListener("visibilitychange", async () => {
              if (wakeLock !== null && document.visibilityState === "visible") {
                wakeLock = await (navigator as any)?.wakeLock?.request(
                  "screen"
                );
              }
            });
          }
        } catch (err) {
          console.error("Falha ao ativar o Wake Lock:", err);
        }
      } else {
        console.log(
          "Screen Wake Lock API não está disponível neste navegador."
        );
      }
    };

    requestWakeLock();
  }, [navigator]);

  const clickDiv = () => {
    const div = document.getElementById("clickMeDiv");
    div?.click();
  };

  useEffect(() => {
    const intervalId = setInterval(clickDiv, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      style={{
        marginLeft: "30px",
        marginRight: "30px",
        marginBottom: "30px",
        height: "100%",
      }}
    >
      <>
        <button
          id="clickMeDiv"
          style={{
            position: "absolute",
            top: "-20px",
            width: "1px",
            height: "1px",
          }}
          onClick={() => {
            console.log("clicou");
          }}
        />
        {isPermittedAudio ? (
          <>
            <div
              style={{
                gap: "20px",
                width: "100%",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            />
            <Info
              id={v4()}
              calls={calls}
              call={calls.length > 0 ? calls[0] : {}}
            />
            <Wheather />
          </>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              style={{
                width: "50%",
                height: "10%",
                fontSize: "2rem",
                fontWeight: "bold",
              }}
              className="btn btn-success btn-block"
              onClick={() => {
                setIsPermittedAudio(true);
                localStorage.setItem("@App:permittedAudio", "true");
              }}
            >
              Permitir a reprodução de aúdio e localização
            </button>
          </div>
        )}
        <Modal
          open={isExecuting}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              variant="h1"
              component="h1"
              align="center"
              color={"white"}
              fontWeight={"600"}
              id="modal-modal-title"
              className="texto-piscante2"
              sx={{ fontSize: "clamp(6rem, 8rem, 9rem)" }}
            >
              {calls[0]?.password}
            </Typography>
            <Typography
              variant="h2"
              align="center"
              component="h2"
              sx={{ mt: 2, fontSize: "clamp(3rem, 5rem, 6rem)" }}
              color={"white"}
              fontWeight={"600"}
              className="texto-piscante2"
              id="modal-modal-description"
            >
              {calls[0]?.local}
            </Typography>
            <Typography
              variant="h2"
              align="center"
              component="h2"
              sx={{ mt: 2, fontSize: "clamp(3rem, 5rem, 6rem)" }}
              color={"white"}
              fontWeight={"600"}
              className="texto-piscante2"
              id="modal-modal-description"
            >
              {calls[0]?.name}
            </Typography>
          </Box>
        </Modal>
        <Modal
          open={haveBacked}
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          aria-labelledby="modal-modal-title"
          onClose={() => setHaveBacked(false)}
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleHaveBacked}>
            <button
              type="submit"
              style={{
                width: "50%",
                marginBottom: "20px",
              }}
              onClick={() => {
                // window.location.reload()
                navigate("/", { replace: true, state: { haveBacked: true } });
              }}
              className="btn btn-success btn-block btn-form"
            >
              Reiniciar
            </button>
            <button
              style={{
                width: "50%",
                marginBottom: "20px",
              }}
              onClick={() => {
                localStorage.clear();
                window.location.reload();
                Navigate({ to: "/", replace: true });
              }}
              className="btn btn-primary btn-block btn-form"
            >
              Sair
            </button>
          </Box>
        </Modal>
      </>
    </div>
  );
}

const style = {
  p: 4,
  width: "50%",
  height: "50%",
  boxShadow: 24,
  display: "flex",
  borderRadius: "30px",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  outline: "none !important",
  backgroundColor: "#174B7A",
  position: "absolute" as "absolute",
};

const styleHaveBacked = {
  p: 4,
  gap: "20px",
  width: "30%",
  height: "8%",
  boxShadow: 24,
  display: "flex",
  borderRadius: "30px",
  alignItems: "center",
  outline: "none !important",
  backgroundColor: "white",
  justifyContent: "space-evenly",
  position: "absolute" as "absolute",
};
