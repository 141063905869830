import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "../contexts/auth";
import LoginForm from "../components/login-form";
import { api } from "../services/api";
import PainelForm from "../components/painel-form";
import axios from "axios";
import { removerAcentos } from "../utils/formatString";

export interface IPainel {
  PAICODIGO: number;
  CLICODIGO: number;
  PAICIDADE: string;
  PAIESTADO: string;
  PAIUSACLIMA: number;
  PAIENDERECO: string;
  PAILATITUDE: string;
  PAILONGITUDE: string;
  PAIDESCRICAO: string;
  PAIUSAPLAYLIST: number;
  PAILINKPLAYLIST: string;
}

interface LocationInfo {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  class: string;
  type: string;
  place_rank: number;
  importance: number;
  addresstype: string;
  name: string;
  display_name: string;
  address: {
    road: string;
    residential: string;
    suburb: string;
    municipality: string;
    county: string;
    state_district: string;
    state: string;
    "ISO3166-2-lvl4": string;
    region: string;
    postcode: string;
    country: string;
    country_code: string;
  };
  boundingbox: [string, string, string, string];
}

export default function Login() {
  const [isSelectedPainel, setIsSelectedPainel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paineisOptions, setPaineisOptions] = useState<IPainel[]>([]);
  const [userData, setUserData] = useState<{
    jwtusername?: string;
    jwtpassword?: string;
  }>({});
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
  }, []);

  async function login(data: any) {
    const req = await api();
    setIsLoading(true);
    try {
      const res = await req.post<{ token: string }>("/auth/login", data);
      if (res.status === 200) {
        const { token } = res.data;
        localStorage.setItem("@App:Token_Key", token);
        try {
          const resGet = await req.get<IPainel[]>("/paineis", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setPaineisOptions(resGet.data);
          setUserData(data);
          setIsSelectedPainel(true);
        } catch (e) {
          toast.error(
            "Falha ao localizar os paineis. Entre em contato com o suporte."
          );
          localStorage.clear();
        }
      }
    } catch (err) {
      toast.error(err.response.data.message);
      localStorage.clear();
    } finally {
      setIsLoading(false);
    }
  }

  async function select(data: any) {
    const req = await api();
    setIsLoading(true);
    try {
      const res = await req.post<{ token: string }>("/auth/login", {
        ...userData,
        ...data,
      });
      if (res.status === 200) {
        const { token } = res.data;
        let panelSelected = paineisOptions.find(
          (item) => item.PAICODIGO == data.PAICODIGO
        ) as IPainel;
        let dataFormated = {
          ...panelSelected,
        };
        if (panelSelected?.PAIUSACLIMA) {
          let responseAPI: LocationInfo[] = (
            await axios.get<LocationInfo[]>(
              `https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&country=Brazil&state=${
                panelSelected?.PAIESTADO
              }&city=${removerAcentos(
                panelSelected?.PAICIDADE
              )?.toLowerCase()}&street=${panelSelected?.PAIENDERECO}`
            )
          ).data;
          const responseAPIF = responseAPI[responseAPI.length - 1];
          dataFormated = {
            ...dataFormated,
            PAILATITUDE: responseAPIF?.lat ,
            PAILONGITUDE: responseAPIF?.lon,
          };
        }
        if (panelSelected?.PAIUSAPLAYLIST == 1) {
          const match =
            panelSelected?.PAILINKPLAYLIST.match(/[?&]list=([^&]+)/);
          const result = match && match[1];
          panelSelected = {
            ...panelSelected,
            PAILINKPLAYLIST: result,
          };
          dataFormated = {
            ...dataFormated,
            PAILINKPLAYLIST: result,
          };
        }
        if (
          panelSelected?.PAIUSACLIMA == 1 ||
          panelSelected?.PAIUSAPLAYLIST == 1
        ) {
          localStorage.setItem("@App:Panel", JSON.stringify(dataFormated));
        }
        localStorage.setItem("@App:Token_Painel", token);
        setAuth(true);
        navigate("/");
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isSelectedPainel ? (
        <PainelForm
          select={select}
          isLoading={isLoading}
          options={paineisOptions}
        />
      ) : (
        <LoginForm login={login} isLoading={isLoading} />
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
