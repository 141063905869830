import { useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";

import logo from '../logo-prosesmt.png';

interface ILoginFormProps {
  login: (data: any) => void;
  isLoading: boolean;
}

export default function LoginForm ({ login, isLoading }: ILoginFormProps) {
  const { register, handleSubmit } = useForm();

  return (
    <div className="global-container">
      <div className="card login-form" style={{ width: '400px', padding: '15px' }}>
        <div className="card-body">
          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
            <img src={logo} alt="" />
          </div>
          <h3 className="card-title text-center fw-normal">Painel de Senha</h3>
          <div className="card-text">
            <form onSubmit={handleSubmit(login)}>
              <div className="form-group mb-3">
                <label
                  htmlFor="inputUser"
                  className="form-label"
                >
                  Usuário
                </label>
                <input
                  type="email"
                  className="form-control form-control-sm"
                  style={{ padding: '10px 7px', fontSize: '16px' }}
                  id="inputUser"
                  aria-describedby="userUsername"
                  { ...register('jwtusername', { required: true })}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputPassword" className="form-label">Senha</label>
                <a href="#/" style={{ float: 'right', fontSize: '12px' }}>
                  Esqueceu a senha?
                </a>
                <input
                  type="password"
                  className="form-control form-control-sm"
                  style={{ padding: '10px 7px', fontSize: '16px' }}
                  id="inputPassword"
                  aria-describedby="userPassword"
                  { ...register('jwtpassword', { required: true })}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block btn-form"
              >
                { isLoading ? <Loader type="Oval" color="white" height={20} /> : 'Próximo' }
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}