import { useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"

export const Confirm = () => {

    const navigate = useNavigate()
    const { state } = useLocation() as any

    useEffect(() => {
        const token = localStorage.getItem("@App:Token_Painel")
        if (!!token) {
            return
        } else {
            navigate("/login")
        }
    }, [])

    useEffect(() => {
        if (state?.haveBacked) {
            navigate("/", { replace: true, state: null })
            window.location.reload()
        }
    }, [state])

    return (
        <>
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <button
                    type="submit"
                    style={{
                        width: "50%",
                        height: "10%",
                        fontSize: "2rem",
                        fontWeight: "bold",
                    }}
                    onClick={() => navigate("/painel", { state: { haveBacked: true } })}
                    className="btn btn-primary btn-block btn-form"
                >
                    INICIAR ATENDIMENTO
                </button>
            </div>
        </>
    )
}