import {
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';

interface IAuthProviderProps {
  children: JSX.Element
}

interface IAuthContext {
  setAuth: (isAuthenticated: boolean) => void;
  isAuthenticated: boolean;
}

const def = {} as IAuthContext;

const AuthContext = createContext(def);

export function useAuth () {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }: IAuthProviderProps) {

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const tokenKey = localStorage.getItem("@App:Token_Key");
    return tokenKey ? true : false;
  });

  const setAuth = useCallback(
    (isAuthenticated) => {
      setIsAuthenticated(isAuthenticated);
    },
    []
  );

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuth,
      }}
    >
      { children }
    </AuthContext.Provider>
  );
}
