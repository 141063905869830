import { CSSProperties } from "react";
import { ICall } from "../pages/home";

export default function Calls({
  calls,
  styles,
}: {
  calls: ICall[];
  styles?: CSSProperties;
}) {
  return (
    <div
      style={{
        width: "50%",
        height: "100%",
        padding: "0px",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          padding: "20px",
          display: "flex",
          overflow: "auto",
          alignItems: "end",
          flexDirection: "column",
          justifyContent: "flex-start",
          ...styles,
        }}
        className="info text-end border border-white text-uppercase b-blue d-flex align-items-center"
      >
        {calls.length > 0 && (
          <>
            <h4
              className="fw-bold text-light"
              style={{
                width: "100%",
                textAlign: "start",
                fontSize: "clamp(3rem, 4rem, 5rem)",
              }}
            >
              Últimas chamadas
            </h4>
            <table className="table">
              <tbody>
                {calls.map((call, index) => (
                  <tr
                    key={index}
                    style={{
                      textAlign: "start",
                      fontWeight: "600",
                      fontSize: "1.2rem",
                      color: "white",
                    }}
                  >
                    <td style={{ border: "none", fontSize: "clamp(1.5rem, 2.5rem, 3rem)" }}>
                      {call?.name}
                    </td>
                    <td style={{ border: "none", fontSize: "clamp(1.5rem, 2.5rem, 3rem)" }}>
                      {call?.password}
                    </td>
                    <td style={{ border: "none", fontSize: "clamp(1.5rem, 2.5rem, 3rem)" }}>
                      {call?.local}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
}
