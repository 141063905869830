export default async function playAudio(
  data: any,
  setIsExecuting: (value: boolean) => void,
  setCalls: (value: (data: any) => any) => void
) {
  setIsExecuting(true);

  async function verifyCall() {
    setCalls((oldCalls) => {
      if (oldCalls.length >= 20) {
        return [data.call, ...oldCalls.slice(0, -1)];
      }

      let call = [];
      call = [data.call, ...oldCalls];
      return call;
    });
  }

  let index = 1;

  await verifyCall();

  async function verifySrc() {
    if (data.output) {
      const srcs = await Promise.all(
        data.output.map(async (src) => `/sons/${src}.wav`)
      );
      return srcs;
    } else {
      return [];
    }
  }

  const srcs = await verifySrc();
  try {
    const audio = new Audio(srcs[0]);
    audio.loop = false;
    audio.play()
      .catch((err) => {
        const audio2 = new Audio(srcs[0]);
        audio2.loop = false;
        audio2.play().catch((err) => {
          setIsExecuting(false);
        });
        audio2.onended = () => {
          if (index < data.output.length) {
              audio2.src = srcs[index];
              audio2.play();
              index += 1;
          } else {
            setIsExecuting(false);
          }
        };
        audio.onerror = () => {
          setIsExecuting(false);
        };
      });
    audio.onended = () => {
      if (index < data.output.length) {
        audio.src = srcs[index];
        audio.play();
        index += 1;
      } else {
        setIsExecuting(false);
      }
    };
    audio.onerror = () => {
      const audio2 = new Audio(srcs[0]);
      audio2.loop = false;
      audio2.play().catch((err) => {
        setIsExecuting(false);
      });
      audio2.onended = () => {
        if (index < data.output.length) {
          setTimeout(() => {
            audio2.src = srcs[index];
            audio2.play();
            index += 1;
          }, 500);
        } else {
          setIsExecuting(false);
        }
      };
      audio2.onerror = () => {
        setIsExecuting(false);
      };
    };
  } catch (err) {
    setIsExecuting(false);
  }
}
