import axios from "axios";
import { LuWind } from "react-icons/lu";
import { IPainel } from "../pages/login";
import { IoMdRainy } from "react-icons/io";
import { BsMoisture } from "react-icons/bs";
import { useEffect, useState } from "react";
import { RiMapPin2Fill } from "react-icons/ri";
import YouTube from "react-youtube";

interface DayForecast {
  temp: number;
  icon: string;
  tempmax: number;
  tempmin: number;
  humidity: number;
  windspeed: number;
  precipprob: number;
  description: string;
  datetimeEpoch: number;
}

interface CurrentConditions {
  temp: number;
  icon: string;
  humidity: number;
  windspeed: number;
  precipprob: number;
  datetimeEpoch: number;
}

interface WeatherForecast {
  address: string;
  latitude: number;
  timezone: string;
  tzoffset: number;
  queryCost: number;
  longitude: number;
  days: DayForecast[];
  resolvedAddress: string;
  currentConditions: CurrentConditions;
}

export default function Wheather() {
  const panelInfo = JSON.parse(localStorage.getItem("@App:Panel")) as IPainel;

  const [days, setDays] = useState<{ day: string; date: Date; info: DayForecast }[]>([])
  const [wheatherInformation, setWheatherInformation] = useState<WeatherForecast>(null);

  useEffect(() => {
    const getInformationWheather = async () => {
      const { data } = await axios.get<WeatherForecast>(
        "https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/-23.4400582,-46.5734524?key=57QDSPYGLJ9B62YXCAG4SA64C&lang=pt&unitGroup=metric&iconSet=icons2&include=days,current&elements=datetimeEpoch,tempmax,tempmin,temp,humidity,windspeed,icon,precipprob,description"
      );
      setWheatherInformation(data);
    };

    getInformationWheather();
    const intervalId = setInterval(getInformationWheather, 3600000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (days.length === 0 && wheatherInformation) {
      const daysF: { day: string; date: any; info: DayForecast }[] = []
      wheatherInformation.days.forEach((item, index) => {
        if (index !== 0 && index <= 5) {
          const data = new Date(item.datetimeEpoch * 1000);
          const formatador = new Intl.DateTimeFormat('pt-BR', { weekday: 'long' });
          const nomeDoDia = formatador.format(data).replace(/^\w/, (c) => c.toUpperCase());
          daysF.push({ day: nomeDoDia.includes("-") ? nomeDoDia.split("-")[0] : nomeDoDia, date: data, info: item })
        }
      })
      setDays(daysF)
    }
  }, [days, wheatherInformation])

  if (!panelInfo?.PAIUSACLIMA && !panelInfo?.PAIUSAPLAYLIST) return <></>;

  const opts = {
    width: "100%",
    height: "100%",
    playerVars: {
      listType: "playlist",
      list: panelInfo?.PAILINKPLAYLIST,
      autoplay: 1,
      controls: 1,
      rel: 0,
      showinfo: 0,
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: panelInfo.PAIUSAPLAYLIST ? "36%" : "22%",
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
        }}
      >
        {
          panelInfo?.PAIUSAPLAYLIST && panelInfo?.PAIUSACLIMA
            ? (
              <>
                <div className="wheather2 border">
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                    }}
                  >
                    <img src={`/assets/icons-wheather/${wheatherInformation?.currentConditions?.icon}.png`} alt=""></img>
                    <div
                      style={{
                        width: "50%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          gap: "10px",
                          height: "30%",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <RiMapPin2Fill size={30} />
                        <p
                          style={{
                            margin: "0",
                            fontSize: "2rem",
                            fontWeight: "600",
                          }}
                        >
                          {
                            panelInfo?.PAICIDADE
                          }
                        </p>
                      </div>
                      <div
                        style={{
                          height: "45%",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            width: "30%",
                            height: "85%",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "11px",
                            justifyContent: "space-evenly",
                            backgroundColor: "rgba(20, 73, 121, 0.45)",
                          }}
                        >
                          <LuWind size={40} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center"
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                fontWeight: "400",
                                fontSize: "0.8rem",
                              }}
                            >
                              Vento
                            </p>
                            <p
                              style={{
                                margin: "0",
                                fontWeight: "700"
                              }}
                            >
                              {
                                `${wheatherInformation?.currentConditions?.windspeed} km/h`
                              }
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "30%",
                            height: "85%",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "11px",
                            justifyContent: "space-evenly",
                            backgroundColor: "rgba(20, 73, 121, 0.45)",
                          }}
                        >
                          <BsMoisture size={40} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center"
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                fontWeight: "400",
                                fontSize: "0.8rem",
                              }}
                            >
                              Umidade
                            </p>
                            <p
                              style={{
                                margin: "0",
                                fontWeight: "700"
                              }}
                            >
                              {
                                `${wheatherInformation?.currentConditions?.humidity}%`
                              }
                            </p>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "30%",
                            height: "85%",
                            color: "white",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "11px",
                            justifyContent: "space-evenly",
                            backgroundColor: "rgba(20, 73, 121, 0.45)",
                          }}
                        >
                          <IoMdRainy size={40} />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center"
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                fontWeight: "400",
                                fontSize: "0.8rem",
                              }}
                            >
                              Chuva
                            </p>
                            <p
                              style={{
                                margin: "0",
                                fontWeight: "700",
                                textAlign: "center",
                              }}
                            >
                              {
                                `${wheatherInformation?.days[0].precipprob}%`
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                      }}
                    >
                      <h1
                        style={{
                          margin: "0",
                          display: "flex",
                          fontSize: "3.5rem",
                        }}
                      >
                        {wheatherInformation?.currentConditions?.temp}
                        <p
                          style={{
                            margin: "0",
                            fontSize: "1rem"
                          }}
                        >
                          ºC
                        </p>
                      </h1>
                      <div
                        style={{
                          width: "80%",
                          display: "flex",
                          justifyContent: "space-evenly"
                        }}
                      >
                        <p style={{ margin: "0", }}>
                          {wheatherInformation?.days[0].tempmax.toFixed(0)}º
                        </p>
                        <p style={{ color: "rgba(204, 204, 204, 1)", margin: "0" }}>
                          {wheatherInformation?.days[0].tempmin.toFixed(0)}º
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: "20px",
                      width: "85%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {
                      days?.map((day, index) => (
                        <div
                          key={index}
                          style={{
                            width: "20%",
                            height: "65%",
                            color: "white",
                            display: "flex",
                            borderRadius: "20px",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.25)",
                          }}
                        >
                          {
                            <>
                              <div>{day.day}</div>
                              <img src={`/assets/icons-wheather/${day.info.icon}.png`} alt=""></img>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px"
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0"
                                  }}
                                >
                                  {day.info.tempmax.toFixed(0)}º
                                </p>
                                <p
                                  style={{
                                    margin: "0",
                                    color: "rgba(204, 204, 204, 1)"
                                  }}
                                >
                                  {day.info.tempmin.toFixed(0)}º
                                </p>
                              </div>
                            </>
                          }
                        </div>
                      ))
                    }
                  </div>
                </div>
                <YouTube
                  style={{
                    width: "50%",
                    height: "100%"
                  }}
                  opts={opts}
                  className="border"
                />
              </>
            )
            : panelInfo?.PAIUSACLIMA
              ? (
                <div className="wheather">
                  <img src={`/assets/icons-wheather/${wheatherInformation?.currentConditions?.icon}.png`} alt=""></img>
                  <div
                    style={{
                      width: "30%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        gap: "10px",
                        height: "30%",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <RiMapPin2Fill size={30} />
                      <p
                        style={{
                          margin: "0",
                          fontSize: "2rem",
                          fontWeight: "600",
                        }}
                      >
                        {
                          panelInfo?.PAICIDADE
                        }
                      </p>
                    </div>
                    <div
                      style={{
                        height: "45%",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                    >
                      <div
                        style={{
                          width: "30%",
                          height: "85%",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "11px",
                          justifyContent: "space-evenly",
                          backgroundColor: "rgba(20, 73, 121, 0.45)",
                        }}
                      >
                        <LuWind size={40} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                          }}
                        >
                          <p
                            style={{
                              margin: "0",
                              fontWeight: "400",
                              fontSize: "0.8rem",
                            }}
                          >
                            Vento
                          </p>
                          <p
                            style={{
                              margin: "0",
                              fontWeight: "700"
                            }}
                          >
                            {
                              `${wheatherInformation?.currentConditions?.windspeed} km/h`
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30%",
                          height: "85%",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "11px",
                          justifyContent: "space-evenly",
                          backgroundColor: "rgba(20, 73, 121, 0.45)",
                        }}
                      >
                        <BsMoisture size={40} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                          }}
                        >
                          <p
                            style={{
                              margin: "0",
                              fontWeight: "400",
                              fontSize: "0.8rem",
                            }}
                          >
                            Umidade
                          </p>
                          <p
                            style={{
                              margin: "0",
                              fontWeight: "700"
                            }}
                          >
                            {
                              `${wheatherInformation?.currentConditions?.humidity}%`
                            }
                          </p>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "30%",
                          height: "85%",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          borderRadius: "11px",
                          justifyContent: "space-evenly",
                          backgroundColor: "rgba(20, 73, 121, 0.45)",
                        }}
                      >
                        <IoMdRainy size={40} />
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"
                          }}
                        >
                          <p
                            style={{
                              margin: "0",
                              fontWeight: "400",
                              fontSize: "0.8rem",
                            }}
                          >
                            Chuva
                          </p>
                          <p
                            style={{
                              margin: "0",
                              fontWeight: "700",
                              textAlign: "center",
                            }}
                          >
                            {
                              `${wheatherInformation?.days[0].precipprob}%`
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      color: "white",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "center",
                    }}
                  >
                    <h1
                      style={{
                        margin: "0",
                        display: "flex",
                        fontSize: "3.5rem",
                      }}
                    >
                      {wheatherInformation?.currentConditions?.temp}
                      <p
                        style={{
                          margin: "0",
                          fontSize: "1rem"
                        }}
                      >
                        ºC
                      </p>
                    </h1>
                    <div
                      style={{
                        width: "80%",
                        display: "flex",
                        justifyContent: "space-evenly"
                      }}
                    >
                      <p style={{ margin: "0", }}>
                        {wheatherInformation?.days[0].tempmax.toFixed(0)}º
                      </p>
                      <p style={{ color: "rgba(204, 204, 204, 1)", margin: "0" }}>
                        {wheatherInformation?.days[0].tempmin.toFixed(0)}º
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      gap: "20px",
                      width: "40%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    {
                      days?.map((day, index) => (
                        <div
                          key={index}
                          style={{
                            width: "20%",
                            height: "65%",
                            color: "white",
                            display: "flex",
                            borderRadius: "20px",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            backgroundColor: "rgba(255, 255, 255, 0.25)",
                          }}
                        >
                          {
                            <>
                              <div>{day.day}</div>
                              <img src={`/assets/icons-wheather/${day.info.icon}.png`} alt=""></img>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px"
                                }}
                              >
                                <p
                                  style={{
                                    margin: "0"
                                  }}
                                >
                                  {day.info.tempmax.toFixed(0)}º
                                </p>
                                <p
                                  style={{
                                    margin: "0",
                                    color: "rgba(204, 204, 204, 1)"
                                  }}
                                >
                                  {day.info.tempmin.toFixed(0)}º
                                </p>
                              </div>
                            </>
                          }
                        </div>
                      ))
                    }
                  </div>
                </div>
              )
              : <YouTube
                style={{
                  width: "100%",
                  height: "100%"
                }}
                opts={opts}
                className="border"
              />
        }
      </div>
    </div>
  );
}
