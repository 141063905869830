import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AuthProvider from "./contexts/auth";
import PrivateRoute from "./components/private-route";
import Home from "./pages/home/index";
import Login from "./pages/login";
import { Confirm } from "./pages/confirm";

function App() {
  
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/painel"
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route
            path="/"
            element={<Confirm />}
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
