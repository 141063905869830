import axios from "axios";

export async function api() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    timeout: 120000,
  });

  return instance;
}
