import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

interface IPrivateRouteProps {
  children: JSX.Element
}

export default function PrivateRoute ({ children }: IPrivateRouteProps): JSX.Element | null {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />
}
