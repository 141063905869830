import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";

import logo from '../logo-prosesmt.png';

interface IPainelFormProps {
  options: any[];
  select: (data: any) => void;
  isLoading: boolean;
}

export default function PainelForm ({ options, select, isLoading }: IPainelFormProps) {
  const { register, handleSubmit } = useForm();

  return (
    <div className="global-container">
      <div className="card login-form" style={{ width: '400px', padding: '15px' }}>
        <div className="card-body">
        <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
            <img src={logo} alt="" />
          </div>
          <h3 className="card-title text-center fw-normal">Painel de Senha</h3>
          <div className="card-text">
            <form onSubmit={handleSubmit(select)}>
              <div className="form-group">
                <label htmlFor="inputPainel" className="form-label">
                  Selecione um painel
                </label>
                <select
                  id="inputPainel"
                  style={{ padding: '10px 7px', fontSize: '16px' }}
                  className="form-select form-control"
                  { ...register('PAICODIGO', { required: true }) }
                >
                  {
                    options.map((option, idx: number) => (
                      <option value={option.PAICODIGO} key={idx}>{option.PAIDESCRICAO}</option>
                    ))
                  }
                </select>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block btn-form"
              >
                { isLoading ? <Loader type="Oval" color="white" height={20} /> : 'Selecionar' }
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}