import "./styles.css";
import { ICall } from "./index";
import { IPainel } from "../login";
import Calls from "../../components/calls";
import { useEffect, useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";

interface IInfoProps {
  id: string;
  call: ICall;
  calls: ICall[];
}

export default function Info({ call, id, calls }: IInfoProps) {
  const [animation, setAnimation] = useState(false);
  const [panelInfo, setPanelInfo] = useState<IPainel | null>(null);

  const [width] = useWindowSize();

  useEffect(() => {
    const panel = JSON.parse(localStorage.getItem("@App:Panel")) as IPainel;
    setPanelInfo(panel);
    setAnimation(true);
  }, [id]);

  return (
    <>
      <div
        className="row"
        style={{
          margin: 0,
          height:
            panelInfo?.PAIUSAPLAYLIST
              ? "60%"
              : panelInfo?.PAIUSACLIMA == 1
                ? "74%"
                : "95%",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            width: "50%",
            padding: "0",
            height: "100%",
          }}
          className="col info"
        >
          <iframe
            width={`${(width - 60 - 50) * 0.5}px`}
            id="ytplayer"
            height="45%"
            style={{
              zIndex: "-1",
              height: "45%",
              position: "absolute",
              borderRadius: "15px",
              width: `${(width - 60 - 50) * 0.5}px`,
            }}
            frameBorder="0"
            allowFullScreen
            allow="autoplay"
            src={`https://www.youtube.com/embed/?listType=playlist&list=PLtfdlZOOAlUciEA9wD0wa30bFgixuvzrq&autoplay=1&mute=1&controls=0`}
          />
          <div
            style={{
              height: "70%",
            }}
            className="passwordBox col-sm text-center border border-white text-uppercase b-blue d-flex align-items-center justify-content-center half"
          >
            <p
              className={`f100 fw-bold ${animation ? "texto-piscante" : "text-light"
                }`}
              style={{
                margin: "0",
              }}
              onAnimationEnd={() => setAnimation(false)}
            >
              {call?.password}
            </p>
            {
              (call?.name && call?.name?.length > 0) && (
                <p
                  className={`f50 inter-600 fw-bold ${animation ? "texto-piscante" : "text-light"
                    }`}
                  style={{
                    margin: "0",
                  }}
                  onAnimationEnd={() => setAnimation(false)}
                >
                  {call?.name}
                </p>
              )
            }
          </div>
          <div className="roomBox col-sm text-center border border-white text-uppercase b-blue d-flex align-items-center justify-content-center">
            <p style={{ margin: "0" }} className="f35 text-light fw-bold">
              {call?.local}
            </p>
          </div>
        </div>
        <Calls calls={calls} />
      </div >
    </>
  );
}
